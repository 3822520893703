.importPage{
	width: 100%;
	height: calc(100vh - 50px);

	&__layout{
		padding: 20px;
	}

	&__main{
		width: 100%;
		height: 100%;
		padding: 0 12px 15px;
		background: var(--gray-50);
		box-shadow: var(--container-shadow);
	}

	&__block{
		width: 100%;
		padding-top: 10px;

		&_sub_block{
			display: flex;
			align-items: center;
		}
	}

	&__title{
		width: 50%;
		margin: 0 0 15px 34px;
		color: var(--purple-400);
		font-weight: var(--medium-weight);
		font-size: 18px;

		&_update{
			color: #EB5757;
			line-height: 19px;
		}
	}

	&__container{
		display: flex;
		gap: 6px;
	}

	&__info-block{
		display: flex;
		gap: 70px;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 10px 20px 10px 40px;
		background: var(--white-100);
        box-shadow: var(--box-shadow);
	}

	&__info-block1{
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 10px;
		justify-content: center;
		width: 100%;
		padding: 7px 20px 7px 40px;
		background: var(--white-100);
        box-shadow: var(--box-shadow);
	}

	&__info-block-text{
		color: var(--purple-400);
	}

	&__info-block-tooltips{
		margin-left: auto;
	}

	&__info-block-link{
		display: flex;
		gap: 50px;
		align-items: center;

	}

	&__help{
      &-list li{
          margin-bottom: 5px;
      }
	}
}

.icon__link-cotainer{
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 46px;
	height: 29px;
	border-radius: var(--radius);
	background: var(--purple-400);
	cursor: pointer;
}

.icon-link{
	display: inline-block;
	width: 20px;
	height: 20px;
	background-image: url("../../media/link.png");
	background-repeat: no-repeat;
	background-position: center;
}

.blockPro100{
	height: 80px;
}

.textForPro100{
	width: calc(100% - 10px);
}

.error_text{
	color: #ff00008f;
}

.import__tooltip {
  max-width: 400px;

  &--big {
    max-width: 600px;
  }

  & > p > b{
    color: var(--purple-400);
  }

  &-text{
    font-size: 14px;
  }

  &-img {
    display: flex;
    align-self: flex-start;
    margin-left: auto;
    cursor: pointer;
  }
}