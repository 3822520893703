.project-calc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 1024px;
  padding: 16px 0;
  user-select: none;

  &__select {
    &-option {
      padding: 15px 10px;
      line-height: 10px;
      cursor: pointer;
    }

    &-option:hover{
      background: #F3E8F0;
    }
  }

  &__name {
    display: flex;
    min-width: 176px;
    font-size: 18px;
    line-height: 22px;

  }

  &__center {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &-tooltip {
    &.react-tooltip {
      padding: 10px;
    }
  }

  &__id {
    display: flex;
    margin-left: 10px;
  }

  &__btns {
    display: flex;
    gap: 26px;
    align-items: center;
    justify-content: space-between;
    margin-right: 8px;
  }

  &__icon-add {
    position: relative;
    display: inline-block;
    width: 11px;
    height: 11px;
    background: url("../../../media/add.svg") no-repeat 50% 50%;
  }
}

input.project-calc__import-project{
  position: absolute;
  z-index: -1;
  width: 220px;
  opacity: 0;
}

input.project-calc__import-project+label{
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  height: 30px;
  padding: 0 58px;
  border-color: transparent;
  border-radius: 30px;
  font-size: 14px;
  line-height: 17px;
  background: #f0e4ed;
  cursor: pointer;
  transition: all var(--transition);

  &:hover {
    color: var(--white-100);
    background: var(--purple-400);
  }

  &:disabled {
    background: var(--purple-50);
    cursor: not-allowed;
  }
}


