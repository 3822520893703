header {
  .logo {
    width: 120px;
    height: 24px;

    > img {
      width: 100%;
      object-fit: cover;
    }
  }

  .toggle {
    position: absolute;
    top: 37px;
    right: 0;
    display: none;
    width: 195px;
    padding: 5px;
    border: var(--border-width-thin) solid #eceff1;
    border-radius: var(--radius);
    background: var(--white-100);
    box-shadow: 0 1px 2px #00000019;

    &.active {
      display: block;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: right;
      height: 34px;
      padding-right: 24px;
      border-radius: var(--radius);
      color: var(--gray-700);
      font-size: 14px;
      line-height: 100%;
      cursor: pointer;
      transition: var(--transition) all;
      user-select: none;

      &:hover {
        background: #ffe7f7;
      }
    }
  }
}

.header {
  &__user-tooltip {
    &.react-tooltip {
      min-width: 300px;
      max-width: 350px;
      padding: 20px;
    }

    .materialResidues__loader2 {
      padding-top: initial;
    }

    .defaultBtn {
      margin-top: 20px;
      margin-inline: auto;
    }
  }
}

.user {
  display: flex;
  color: var(--purple-400);
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  cursor: pointer;

  &_affiliate {
    margin-top: 15px;
  }

  &_userName{
    display: flex;


    &_colum{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding-left: 16px;

      &_first{
        width: 100%;
        max-width: 220px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &_arrow{
      margin-left: 32px;
    }
  }
}

.rotate{
  transform: rotate(180deg);
  transition: var(--transition) ease;
}

.rotateBack{
  transform: rotate(0deg);
  transition: var(--transition) ease;
}