.hardware-table {
  width: 100%;
  margin-bottom: 55px;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: var(--white-100);

  &__wrapper {
    position: relative;
    height: 100%;
    max-height: 90vh;
    padding-bottom: 10px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--gray-300) var(--white-100);
  }

  &-row-header {
    position: sticky;
    top: 0;
    z-index: 2;
    border-radius: 4px;
    font-size: 14px;
    background-color: #F3F3F3FF;

  }

  &-header-item {
    position: relative;
    padding: 0.5rem;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: var(--gray-300);

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 0.05rem;
      height: 50%;
      background-color: #CED4DAFF;
      transform: translateY(-50%);
      content: '';
    }

    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      width: 0.05rem;
      height: 50%;
      background-color: #CED4DAFF;
      transform: translateY(-50%);
      content: '';
    }

    &.no-border-left::before {
      left: 0;
      height: 100%; /* Высота на весь элемент */
      background-color: #F3F3F3FF; /* Цвет левого бордера */
    }

    /* Специальные стили для последнего столбца (правая граница) */
    &.no-border-right::after {
      right: 0;
      height: 100%; /* Высота на весь элемент */
      background-color: #F3F3F3FF; /* Цвет правого бордера */
    }
  }

  &-header-name {
    padding: 0.5rem 0.5rem 0.5rem 10px;
    font-weight: 400;
    font-size: 14px;
    text-align: start;
    color: var(--gray-300);
  }

  &-item {
    border: 0.05rem solid #F0F0F0;
    color: #1E152AFF;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;

    &__subText{
      max-width: 95%;
      color: rgb(65 65 65 / 70%);
      font-weight: var(--normal-weight);
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      white-space: normal; // Убедимся, что текст переносится
      word-wrap: break-word; // Для совместимости с более старыми браузерами
      overflow-wrap: break-word; // Более современный вариант
      &_link{
        color: var(--gray-500);
        font-weight: var(--normal-weight);
        font-size: 16px;
        line-height: 19.2px;
      }

      &_link:hover{
        color: var(--purple-400);
      }

      &_link:visited{
        color: #470B38;
      }
    }

    &_img{
      width: 73px;
      height: 55px;
      margin-top: 5px;
      border-radius: var(--radius);
      box-shadow: 0 6.67903px 119px rgb(149 149 149 / 24%);
    }

    &__btns{
      display: flex;
      justify-content: center;
    }

    &__button{
      color: var(--purple-400);
      font-size: 20px;
      background-color: var(--white-100);
      cursor: pointer;
    }

    &__button:hover {
      color: var(--purple-800);
    }

    &__default-input-small {
      width: 60px;
      height: 30px;
      border: none;
      border-radius: var(--radius);
      color: rgb(65 65 65 / 70%);
      font-size: 14px;
      text-align: center;
      background-color: var(--form-field-bg);
      outline: none;
    }

    &-first-item {
      width: 3rem;
      overflow: hidden;
      background-color: var(--white-100);
      //background-color: #F3F3F3FF;
      padding-block: 0.5rem;
    }

    &__name {
      width: 37%;
      padding-left: 10px;
      border: 0.05rem solid #F0F0F0;
      color: #1E152AFF;
      font-size: 14px;
      white-space: nowrap;
      text-align: left;
    }
  }
}
