.editFurnitureModal {
    z-index: 100;
    display: flex;
    flex-direction: column;
	width: 1100px;
	min-height: 418px;
    text-align: center;


  &__body {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    padding-inline: var(--modal-indent);
    &-searchField{
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 20px;
    }
    &-forChange{
      margin-top: 20px;
    }
  }


  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
  }
}