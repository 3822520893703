.chosProcesses {
	position: relative;
	width: 100%;
	height: 100%;
	background: #FBFBFB;
	box-shadow: var(--container-shadow);
	opacity: 1;
	transition: all var(--transition) ease;

	@media (width <= 480px) {
		left: 0;
		width: 100%;
		max-width: 400px;

	}

	&__header {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: flex-start;
		justify-content: center;
		width: 563px;
		height: 45px;
		margin-top: 3px;
		padding: 11px 14px;
		background: var(--purple-50);

		@media (width <= 480px) {
			max-width: 330px;
		}
	}

	&__datas {
		position: relative;
		height: calc(100% - 48px);
		margin-right: 3px;
		overflow: hidden auto;

		@media (width <= 480px) {
			width: 100%;
			min-width: 330px;
		}
	}

	&__sort {
		display: flex;
		justify-content: space-between;
		padding: 20px 9px;

		&-item {
			padding: 7.5px 8.5px;
			border-radius: var(--radius);
			font-size: 15px;
			line-height: 19px;
			background: #F0E4ED;
			cursor: pointer;
		}
	}

	&__title {
		display: flex;
		gap: 10px;
		align-items: center;
		padding: 9px 0 16px 9px;
	}

	&__title-icon {
		display: inline-block;
		width: 22px;
		height: 22px;
		background: url('../../../media/icon-notification.png');
	}

	&__title-text {
		&::first-letter {
			text-transform: uppercase;
		}
	}

	&__item {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 621px;
		height: 90px;
		margin-bottom: 5px;
		padding: 5px 10px 0 14px;
		background: var(--white-100);
        box-shadow: var(--box-shadow);
		cursor: pointer;

		&:hover{
			background: #F0E4ED !important;
		}

	&-active{
		background: #F0E4ED
	}

		.chosProcesses__item + & {
			height: 63px;
		}

		&_error {
			background: #FF000026;
		}

		&_tooltip-error{
			height: 60px;

			&_body{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				max-width: 621px;
				height: 100%;
				padding-inline: 15px;
			}

			@media (width <= 480px) {
				width: 100%;
				max-width: 400px;
			}
		}

		&-number{
          &-index{
			  margin-left: 5px;
			  padding-left: 10px;
			  font-size: 14px;
		  }
		}


       &-headers{
		   display: flex;

		   &-checkbox{
			   margin-right: 7px;
			   margin-left: 10px;
		   }
	   }
	}

	&__number {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;
		width: 55px;
		height: 54px;
		margin-right: 10px;
		padding-top: 3px;
		padding-bottom: 5px;

		.chosProcesses__item + .chosProcesses__item> & {
			justify-content: center;
			padding-top: 0;
		}
	}

	&__item-number1 {
		display: none;
		margin-left: 6px;

		.chosProcesses__title + .chosProcesses__item & {
			display: block;
		}
	}

	&__content {
		display: flex;
		gap: 5px;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: 471px;

	}

	&__item-header-title {
		flex: 1;
		min-width: 75px;
		font-size: 14px;
		text-align: center;

		&::first-letter {
			text-transform: uppercase;
		}
	}

	&__comment {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 22px;
		height: 22px;
		border: none;
		background: url('../../../media/comment.png');
		cursor: pointer;
	}

	&__comment-icon {
		display: inline-block;
		width: 22px;
		height: 22px;

	}

	&__item-datas {
		display: flex;
		flex-direction: column;
		gap: 5px;
		justify-content: center;
		width: 100%;
		height: 60px;
		padding-bottom: 1px;
		overflow: auto hidden;
	}

	&__item-header {
		display: none;
		gap: 6px;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		.chosProcesses__title + .chosProcesses__item & {
			display: flex;
		}
	}

	&__item-header-groove {
		display: flex;
		gap: 6px;
		align-items: center;
		width: 100%;

		&_ru{
			gap: 25px;
		}
	}

	&__body {
		height: calc(100% - 5px);

		@media (width <= 480px) {
			width: 100%;
			min-width: 400px;
		}
	}

	&__item-body {
		display: flex;
		gap: 6px;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		&__groove_ru{
			gap: 25px;
		}
	}

	&__item-body-container{
		flex: 1;
		text-align: center;
	}

	&__data {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-width: 75px;
		height: 21px;
		padding: 0 5px;
		font-size: 14px;
		background: #F1F1F1;

		&_error {
			background: #FFE4E4;
			cursor: pointer;
		}

		&::first-letter {
			text-transform: uppercase;
		}
	}

	&__btns {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;
		height: 54px;
		margin-left: 3px;
		padding-bottom: 10px;

		.chosProcesses__item + .chosProcesses__item > &{
			justify-content: center;
			padding-bottom: 5px;
		}
 	}

	&__btns-container {
		display: flex;
		gap: 4px;
	}

	&__btn-edit {
		display: flex;
		width: 22px;
		height: 22px;
		border: none;
		background: url("../../../media/btn-edit.png");
		cursor: pointer;
	}

	&__btn-delete {
		display: flex;
		width: 22px;
		height: 22px;
		border: none;
		background: url("../../../media/btn-delete.png");
		cursor: pointer;
	}

	&__comment-background {
		position: absolute;
		top: 48px;
		z-index: 100000;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: calc(100% - 48px);
		background: #5B5B5B3F;
		backdrop-filter: var(--backdrop);
	}

	&__comment-container {
		width: 509px;
		height: 275px;
	}

	&__comment-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__comment-body {
		display: flex;
		flex-shrink: 0;
		width: 471px;
		height: 198px;
		padding: 13px;
		border: var(--border-width-thin) solid var(--dark-1000);
		border-radius: var(--radius);
		word-break: break-all;
	}

	&__dontProc {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		font-size: 28px;

	}

	&__circle-style {
		justify-content: space-around;
	}

	&__selected {
		background-color: #038F064C;
	}

	&__modal {
		height: 260px;
	}

	&__modal-main{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 80%;
        padding-inline: var(--modal-indent);
	}

	&__modal-container{
		width: 100%;
	}

	&__modal-item{
		margin-bottom: 15px;
	}

	&__modal-input{
		padding-left: 10px;
	}

	&__modal-footer{
		display: flex;
		justify-content: flex-end;
	}
}

div.chosProcesses__item-datas::-webkit-scrollbar {
	width: 5px;
	height: 7px;
	margin-top: 3px;
}

div.chosProcesses__datas::-webkit-scrollbar {
	width: 7px;
}

div.chosProcesses__datas::-webkit-scrollbar-button {
	display: none;
}

.chosProc-icon {
	display: inline-block;
	min-width: 75px;
	height: 21px;
	background-repeat: no-repeat;
	background-position: center;

	@media (width <= 480px) {
		width: 100%;
		min-width: 50px;
	}

	&__angle-left-bottom {
		background-image: url('../../../media/angle-left-bottom.png');
	}

	&__angle-left-top {
		background-image: url('../../../media/angle-left-top.png');
	}

	&__angle-right-bottom {
		background-image: url('../../../media/angle-right-bottom.png');
	}

	&__angle-right-top {
		background-image: url('../../../media/angle-right-top.png');
	}

	&__back-platform {
		background-image: url('../../../media/back-platform.png');
	}

	&__face-platform {
		background-image: url('../../../media/face-platform.png');
	}

	&__right-platform {
		background-image: url('../../../media/right-platform.png');
	}

	&__left-platform {
		background-image: url('../../../media/left-platform.png');
	}

	&__top-platform {
		background-image: url('../../../media/top-platform.png');
	}

	&__bottom-platform {
		background-image: url('../../../media/bottom-platform.png');
	}

	&__vert-proc {
		background-image: url('../../../media/vert-proc.png');

	}

	&__horiz-proc {
		background-image: url('../../../media/horiz-proc.png');
	}
}

.millClassForItemChosProc {
	height: 100px !important;
	margin-bottom: 10px;
}

.millClassForNumber {
    height: 95px !important;

	&__uShape {
		height: 95px !important;
		padding-top: 19px;
	}
}

.millClassForHeader {
	display: flex !important;
}

.rectangleClassForItem {
	height: 91px !important;
	margin-bottom: 10px;
}

.rectangleClassForItemFirstEl {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 21px;
	padding: 0 5px;
	font-size: 14px;
}

.rectangleClassForHeader {
	display: flex !important;
}

.rectangleClassForHeaderTitle:first-child {
    min-width: 100px;
	margin-right: 3px;
}

.featuresModal{
	&__background{
		position: absolute;
		top: 0;
		z-index: 100000;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		background: #5B5B5B3F;
		backdrop-filter: var(--backdrop);
	}
}

.checkbox-display {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 15px;
	height: 16px;
	margin-left: 8px;
	border: var(--border-width-medium) solid var(--purple-400);
	border-radius: 4px;
	cursor: pointer;
	user-select: none;
}

.checkbox-display.checked {
	background: var(--purple-400);
}

.checkbox-display.checked::before {
	display: inline-block;
	width: 12px;
	height: 13px;
	background: url("../../../media/checkbox.svg") no-repeat;
	content: '';
}