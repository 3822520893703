.sale-info {
  display: flex;
  justify-content: center;

  &__title {
    margin-bottom: 25px;
    color: var(--sale-accent-colour);
    font-weight: var(--bold-weight);
    font-size: 20px;
    line-height: 120%;
  }

  &__note {
    margin-top: 20px;
    color: var(--white-100);
    font-size: 14px;
    text-align: center;
  }

  &__list {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;

    @media (width <= 1300px) {
      flex-wrap: wrap;
    }
  }

  &__condition {
    display: flex;
    flex-grow: 1;
    gap: 15px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: var(--border-width-thin) solid #FFFFFF33;
    border-radius: 10px;
    background-color: #7A7777;
    box-shadow: 0 4px 130px 0 #00000040;


    &-icon {
      display: flex;
      width: 100%;
      max-width: 21px;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    &-title {
      color: var(--sale-accent-colour);
      font-weight: var(--medium-weight);
      font-size: 20px;
      line-height: 120%;
    }

    &-desc {
      color: var(--white-100);
      font-weight: var(--medium-weight);
      line-height: 125%;
    }
  }
}