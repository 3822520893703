@import "../../../../styles/mixins.scss";

.modal-add-details {
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 85%;

  @include for-desktop-up {
    width: 65%;
  }

  &__info-container {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 425px;
    overflow: hidden;
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    background: var(--modal-header-bg);
    box-shadow: var(--box-shadow);
    padding-inline: var(--modal-indent);

    &-item {
      font-size: 14px;
      line-height: 17px;
      cursor: pointer;

      &_active {
        height: 80%;
        border-radius: 7px;
        color: var(--white-100);
        background: var(--purple-400);
      }
    }
  }

  &__data-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 750px;
    height: 34px;
    margin: 0 auto;
  }

  &__body{
    flex: 1;
    width: 100%;
    max-height: 600px;
    overflow: auto;

    &-no-parts-found{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 560px;
      height: 100px;

    }
  }

  &_loader{
    width: 100%;
    height: 200px;
  }

  &__body-item{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding: 10px calc(var(--modal-indent) - 7px) 10px var(--modal-indent);
    line-height: 19px;
    background: var(--white-100);
    box-shadow: var(--box-shadow);
  }

  &__data-item{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
  }

  &__imput-item{
    width: 100px;
    border: var(--border-width-thin) solid var(--purple-400);
    border-radius: var(--radius);
    text-align: center;
  }

  &__imput-item:focus{
   outline: none;
  }

  &__checkbox{
    min-width: 20px;
    height: 20px;
    border: var(--border-width-medium) solid var(--purple-400);
    border-radius: var(--radius);
    cursor: pointer;

    &_active{
      background: var(--purple-400);
    }
  }

  &__number{
    width: 15px;
    margin-left: 10px;
    text-align: center;
  }

  &__img{
    width: 33px;
    margin-left: 10px;
    cursor: pointer;
  }

  &__name{
    width: 100%;
    max-width: 100px;
    margin-left: 10px;
    white-space: nowrap;
  }

  &__texture{
    width: 20px;
    height: 20px;
    margin-left: 10px;
    border: var(--border-width-medium) solid var(--purple-400);
    border-radius: var(--radius);

    &_active{
      background: var(--purple-400);
    }
  }

  &__material-block{
    width: 200px;
    margin-right: 0;

    @include for-desktop-up {
      width: 300px;
    }

    &-font{
      font-size: 14px;
    }
  }

  &__item-width{
    width: 100px;
    margin-right: 0;
  }

  &__edges{
    position: relative;
    width: 32px;
    height: 31px;
    border: var(--border-width-thin) solid var(--purple-400);
    border-radius: var(--radius);

    &-top{
      position: absolute;
      top: 4px;
      left: 9px;
      display: inline-block;
      width: 13px;
      height: 8px;
      background: url('../../../../media/tableEdge.svg');
      transform: rotate(180deg);

      &_active{
        background: url('../../../../media/tableEdge-active.svg');
      }
    }

    &-right{
      position: absolute;
      top: 10px;
      right: 1px;
      display: inline-block;
      width: 13px;
      height: 8px;
      background: url('../../../../media/tableEdge.svg');
      transform: rotate(270deg);

      &_active{
        background: url('../../../../media/tableEdge-active.svg');
      }
    }

    &-bottom{
      position: absolute;
      bottom: 4px;
      left: 9px;
      display: inline-block;
      width: 13px;
      height: 8px;
      background: url('../../../../media/tableEdge.svg');

      &_active{
        background: url('../../../../media/tableEdge-active.svg');
      }
    }

    &-left{
      position: absolute;
      top: 10px;
      left: 2px;
      display: inline-block;
      width: 13px;
      height: 8px;
      background: url('../../../../media/tableEdge.svg');
      transform: rotate(90deg);

      &_active{
        background: url('../../../../media/tableEdge-active.svg');
      }
    }

  }

  &__wraperBtn{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__btn-delete{
    margin-left: 90px;
  }

  &__btn-delete-error{
    margin-left: 53px;
  }

  &__icon-error{
    margin-left: 20px;
  }
}

.img22{
  position: unset;
  right: 5%;
  width: 22px;
  height: 22px;
  cursor: pointer;
}