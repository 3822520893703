.quitApp{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  backdrop-filter: var(--backdrop);

  &__modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 743px;
    height: 355px;
    padding: 35px 40px 35px 30px;
    border-radius: 10px;
    background: #FFFFFF;

    &-img{
      width: 100px;
      height: 100px;
      object-fit: contain;
    }

    &-title{
      margin-top: 8px;
      color: rgb(65 65 65 / 100%);
      font-weight: 600;
      font-size: 28px;
    }
  }

  &__header{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__body{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;

  }

  &__text{
    font-size: 30px;
    text-align: center;
  }

  &__footer{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 99px;
  }
}