@import "./mixins.scss";
@import "./animtaions.scss";
@import "./variables.scss";
@import "./normalize.scss";
@import "./libs/index.scss";
@import "./helper.scss";

body {
  min-width: 1250px;
  min-height: 735px;
  overflow-x: auto;
  color: var(--gray-500);
  font-weight: var(--normal-weight);
  font-size: var(--font-size-base);
  background-color: #eff0f7;

  @media (width <= 480px) {
    min-width: 380px;
    max-width: 480px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

.disable-scroll {
  overflow: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

::-webkit-scrollbar {
  width: 11px; /* ширина скроллбара */
}

::-webkit-scrollbar-track {
  background: #d3d3d3; /* цвет фона трека */
}

::-webkit-scrollbar-thumb {
  background: var(--purple-400); /* цвет бегунка */
}

main {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 20px;

  &.process-scene {
    z-index: 2;
    height: calc(100vh - 82px);
  }
}

.process-scene ~ .three-scene {
  display: block;
}

.projects {
  padding-inline: 30px;
  width: 100%;
  height: 100%;

  &__btn {
    display: inline-flex;
    padding-right: 8px;
    color: inherit;
    font-size: 16px;
    text-align: left;
    background-color: transparent;

    path {
      fill: var(--dark-1000);
    }
  }

  &__center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 24px;
  }

  .project-type {
    &__title {
      font-size: 18px;
      line-height: 100%;
    }

    .project-actions {
      display: flex;
      align-items: center;

      &__search {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
      }

      &__input-title {
        margin-bottom: 10px;
        margin-left: 15px;
        font-size: 14px;
      }

      &__input {
        padding: 5px 10px;
        border: var(--border-width-thin) solid var(--dark-1000);
        border-radius: var(--form-field-radius);
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}

.title-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 30px;
  border: none;
  border-radius: var(--radius);
  cursor: pointer;

  &_img {
    border-radius: var(--radius);
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: none;
  border-radius: var(--radius);
  background: var(--purple-400);
  cursor: pointer;

  &__icon-add {
    position: relative;
    display: inline-block;
    width: 11px;
    height: 11px;
    background: url("../media/add.svg") no-repeat 50% 50%;
  }

  &__icon-map {
    display: inline-block;
    width: 35px;
    height: 100%;
    background: url("../media/scissors.svg") no-repeat center center;
  }

  &__icon-delete {
    display: inline-block;
    width: 35px;
    height: 100%;
    background: url("../media/trash-o.svg") no-repeat center center;
    cursor: pointer;
  }

  &__icon-cuttingParams {
    display: inline-block;
    width: 35px;
    height: 100%;
    background: url("../media/cuttingParams.png") no-repeat center center;
  }

  &__icon-angle-up {
    position: relative;
    display: inline-block;
    width: 13px;
    height: 9px;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../media/angle-down.svg") no-repeat 50% 50%;
      transform: rotate(180deg);
      content: "";
    }
  }

  &__icon-angle-down {
    position: relative;
    display: inline-block;
    width: 13px;
    height: 9px;

    &::after {
      position: absolute;
      top: 1px;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../media/angle-down.svg") no-repeat 50% 50%;
      content: "";
    }

  }

  &__icon-edit {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-image: url("../media/edit.svg");
  }
}

.modal-state-enter {
  opacity: 0;
}

.modal-state-enter-active {
  opacity: 1;
  transition: opacity var(--transition);
}

.modal-state-exit {
  opacity: 1;
}

.modal-state-exit-active {
  opacity: 0;
  transition: opacity var(--transition);
}

.three-scene {
  align-items: center;
  justify-content: center;
  width: calc(100% - 198px);
  height: calc(100% - 195px);

  @media (height >= 800px) {
    height: calc(100% - 225px);
  }

  @media (width <= 480px) {
    max-width: 400px;
  }

  &.activated {
    display: flex;
  }

  &-sizes {
    width: 100%;
    height: 100%;

    @media (width <= 480px) {
      width: 80%;
      height: 80%;
    }
  }
}

.body::-webkit-scrollbar-track {
  background: transparent;
}

.body::-webkit-scrollbar-thumb {
  border-radius: var(--radius);
  background: #d0d0d0;
}

.body::-webkit-scrollbar {
  width: 0;
  height: 8px;
}

body.sidebar-open {
  overflow: hidden;

  &::before {
    position: fixed;
    top: 0;
    z-index: 98;
    width: 100%;
    height: 100%;
    background: #A0A0A03D;
    box-shadow: 0 1.736px 1.736px 0 #FFFFFF26 inset, 0 0 26.046px 0 #FFFFFF0C inset;
    content: "";
  }
}