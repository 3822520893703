@import "../../../../styles/mixins.scss";

.addHardware {
  width: 70%;
  max-width: 1860px;
  height: 75%;
  padding: 0 30px 18px;
  color: var(--purple-800);
  font-weight: var(--normal-weight);

  &__loader{
      position: absolute; /* Абсолютное позиционирование относительно ближайшего родителя с position: relative */
      bottom: 50%;         /* Привязка к нижней части контейнера */
      left: 50%;         /* Центрирование по горизонтали */
      z-index: 10; /* Уровень повыше, чтобы перекрывал контент */
      display: flex;
      align-items: center;
      justify-content: center;
      //background: rgba(255, 255, 255, 0.8); /* Прозрачный белый фон поверх таблицы */
      width: 100%;
      height: 40px; /* Высота `Loader` */
      transform: translateX(-50%); /* Смещение для точного центрирования */
      pointer-events: none; /* Делаем слой кликабельным сквозь */
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 0 25px;

    &-title {
      margin: 0;
      padding: 0;
      color: var(--purple-800);
      font-weight: var(--normal-weight);
      font-size: 18px;
      line-height: 22px;
    }

    &-searchField {
      display: flex;
      align-items: center;
      width: 440px;
      max-width: 526px;
      height: 35px;
      padding-right: 21px;
      padding-left: 21px;
      border-radius: 36.293px;
      background: rgb(240 228 237 / 49%);
    }
  }

  &__input {
    width: 370px;
    border: none;
    color: var(--purple-800);
    font-weight: var(--normal-weight);
    font-size: 16px;
    line-height: 19px;
    background-color: transparent;
    outline: none;

    &-icon {
      display: inline-block;
      width: 13px;
      height: 15px;
      margin-right: 15px;
      background: url("../../../../media/loop.png");
    }
  }

  &__colmHeader{
    display: flex;
    justify-content: space-between;
    height: 34px;
    padding-right: 24px;
    padding-left: 142px;

    &-item{
      width: 10%;
      color: rgb(65 65 65 / 70%);
      font-weight: var(--normal-weight);
      font-size: 14px;
      font-style: normal;
      line-height: normal;
    }

    &-item:nth-child(1) {
      width: 50%;
    }
  }

  &__body {
    position: relative;
    width: 100%;
    max-width: 1799px;
    height: calc(100% - 144px);
    overflow: auto;
    background: var(--white-100);

    &_not_find{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      color: var(--purple-800);
      font-weight: var(--normal-weight);
      font-size: 18px;
      line-height: 22px;
    }

    &-block{
      display: flex;
      justify-content: center;
    }

    &-list{
      display: flex;
      flex-direction: column;
      width: 100%;
      //max-width: 1000px;
      background: var(--gray-50);

      &-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        padding: 12px;
        background: var(--white-100);
        box-shadow: 0 6.48375px 115.52067px 0 rgb(149 149 149 / 24%);

        &__default-input {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 7%;
          height: 41px;
          padding-right: 15px;
          padding-left: 15px;
          border-radius: var(--radius);
          color: var(--purple-800);
          background-color: var(--form-field-bg);
        }

        &__default-input-small {
          width: 60px;
          height: 35px;
          border: none;
          border-radius: var(--radius);
          color: rgb(65 65 65 / 70%);
          font-size: 14px;
          text-align: center;
          background-color: var(--form-field-bg);
          outline: none;
        }

        &__input{
          width: 100%;
          padding: 0;
          border: none;
          font-weight: var(--normal-weight);
          font-size: 16px;
          font-style: normal;
          line-height: 24px;
          background: var(--form-field-bg);
          outline: none;
        }

        &-img{
          width: 34px;
          height: 32px;
          border-radius: 9px;
        }

        &-text{
          width: 10%;
          font-size: 16px;

          &-art{
            color: var(--gray-500);
            font-weight: var(--light-weight);
            font-size: 16px;
            font-style: normal;
            line-height: normal;
          }
        }
      }

      &-item > span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &-item > span:nth-child(3) {
        //flex: 5;
        //margin-right: 20px;
        width: 45%;
      }

      &-item > span:nth-child(6) {
        //flex: 5;
        //margin-right: 20px;
        width: 8%;
      }

      &-item:hover {
        background-color: rgb(161 67 137 / 12%);
        cursor: pointer;
      }
  }
}

  &__btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;

    &_wrap{
      display: flex;
      gap: 15px;
      justify-content: space-between;
    }
  }

  &__actions {
    display: flex;
    gap: 16px;
    justify-content: space-between;
  }
}


.edges-header {
  display: flex;
  align-items: center;
  padding: 15px 33px;

  &__checkbox {
    margin-right: 28px;
    cursor: not-allowed;
  }

  &__title-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 390px;

    @include for-modal-up {
      max-width: 350px;
    }
  }

  &__title {
    margin: 0;
    color: var(--purple-400);
    font-size: 18px;
    line-height: 22px;
  }
}

.edges {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: calc(100% - 97px - 58px);
  padding: 0 33px;
  overflow-y: auto;

  &__none{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: var(--purple-800-07);
    font-size: 30px;
  }

  &__checkbox{
    margin-right: 20px;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 95px;
    padding: 19px 0 26px;
    border-top: var(--border-width-thin) solid rgb(161 67 137 / 21%);

    &:nth-child(odd) {
      width: 860px;
      padding-right: 33px;
      border-right: var(--border-width-thin) solid rgb(161 67 137 / 21%);

      @include for-modal-up {
        border-right: none;
      }
    }

    &:nth-child(even) {
      width: 860px;
      padding-left: 44px;
      border-left: var(--border-width-thin) solid rgb(161 67 137 / 21%);

      @include for-modal-up {
        padding-right: 31px;
        padding-left: 0;
        border-left: none;
      }
    }

    &-number {
      box-sizing: content-box;
      width: 20px;
      padding-right: 15px;
      text-align: center;
    }
  }

  &__title-container {
    display: flex;
    gap: 27px;
    align-items: center;
    width: 100%;
    max-width: 345px;
  }

  &__name {
    width: 226px;
    margin: 0;
    color: var(--purple-800);
    font-weight: var(--normal-weight);
    font-size: 15px;
    line-height: 18px;
  }

  &__table {
    display: flex;
    align-items: center;
    width: 100%;

    &-name {
      margin: 0 auto;
      color: var(--purple-800-07);
      font-size: 14px;
      line-height: 17px;

      @include for-modal-up {
        display: none;
      }
    }
  }

  &__data-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 398px;
    padding-right: 71px;

    @include for-modal-up {
      max-width: 362px;
    }
  }

  &__data-container-second {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 410px;
    padding-right: 85px;

    @include for-modal-up {
      display: none;
    }
  }

  &__data {
    width: 60px;
    color: var(--purple-800-07);
    font-size: 14px;
    line-height: 17px;

    &-item {
      width: 60px;
      font-size: 16px;
      text-align: center;
    }
  }
}