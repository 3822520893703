.copyTextComponent{
  position: relative;
  display: inline-block;
  cursor: pointer;
  &__container{
    width: 200px;
    height: 50px;
    padding: 5px;
    background: #1a1919;
    color: #ffffff;
    font-size: 14px;
    border-radius: 5px;
    z-index: 9999;
    text-align: center;
    &-text{
      position: absolute;
      top: 30px;
    }
  }
}