@import "../../../../styles/mixins.scss";

.addCatalog {
  width: calc(100% - 40px);
  height: 90%;



  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      display: flex;

      &-text{
        padding: 0 0 0 15px;
        font-size: 18px;
        line-height: 22px;
      }
    }

    &-searchField {
      display: flex;
      align-items: center;
      width: 440px;
      max-width: 526px;
      height: 35px;
      padding-right: 21px;
      padding-left: 21px;
      border-radius: 36px;
      background: var(--form-field-bg);
    }
  }

  &__input {
    width: 370px;
    border: none;
    line-height: 19px;
    background-color: transparent;
    outline: none;

    &-icon {
      display: inline-block;
      width: 13px;
      height: 15px;
      margin-right: 15px;
      background: url("../../../../media/loop.png");
    }
  }

  &__main{
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    gap: 13px 15px;
    justify-content: flex-start;
    width: 100%;
    height: calc(100% - 140px);
    padding-top: 10px;
    overflow: auto;
    background: var(--white-100);
    padding-inline: var(--modal-indent);

    &_loader{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    &_item{
      width: 290px;
      height: 212px;
      border-radius: var(--radius);
      overflow: hidden;
      background-color: #9595953D;
      cursor: pointer;

      &_img{
        display: flex;
        width: 100%;
        height: 170px;
      }

      &_text{
        display: flex;
        align-items: center;
        width: 100%;
        height: 42px;
        padding-left: 15px;
        line-height: 19px;


      }
    }
  }

  &__items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: calc(100% - 105px);
    padding-top: 10px;
    overflow: auto;
    padding-inline: var(--modal-indent);

    &_item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 80px;
      margin-bottom: 10px;
      background-color: var(--white-100);
      cursor: pointer;

      &_name{
        padding: 23px 22px;
        line-height: 34px;
        letter-spacing: 0;

      }
    }

    &_item > svg{
      margin-right: 34px;
    }

    &_item:hover{
      background-color: var(--purple-50);
    }
  }

  &__body {
    width: 100%;
    max-width: 1799px;
    height: calc(100% - 125px);
    overflow: auto;
    background: var(--white-100);

    &_headerList{
      position: sticky;
      top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 34px;
      padding-right: 24px;
      padding-left: 5%;
      background: var(--white-100);

      &-item{
        width: 10%;
        color: #414141B2;
        font-size: 14px;
      }

      &-item:nth-child(1){
        width: 2%;
      }

      &-item:nth-child(2){
        width: 48%;
      }
    }

    &-block{
      display: flex;
      justify-content: center;
      padding-inline: var(--modal-indent);
    }

    &-list{
      display: flex;
      flex-direction: column;
      width: 100%;
      background: var(--gray-50);

      &-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        padding: 12px;
        background: var(--white-100);
        box-shadow: 0 6.483px 115.520px 0 #9595953D;

        &__default-input {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 9%;
          height: 41px;
          padding-right: 15px;
          padding-left: 15px;
          border-radius: var(--radius);
          background: var(--form-field-bg);
        }

        &__input{
          width: 100%;
          border: none;
          line-height: 24px;
          background-color: var(--form-field-bg);
          outline: none;
        }

        &-img{
          width: 34px;
          height: 32px;
          border-radius: 9px;
        }

        &-text{
          width: 10%;


          &_subText{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          &-art{
            font-weight: var(--light-weight);
          }
        }
      }

      &-item > span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &-item > span:nth-child(3) {
        display: flex;
        gap: 22px;
        align-items: center;
        width: 45%;
      }

      &-item > span:nth-child(4) {
        width: 8%;
      }

      &-item > span:nth-child(6) {
        width: 8%;
      }

      &-item:hover {
        background-color: var(--purple-50);
        cursor: pointer;
      }
  }
}

  &__empty{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 22px;
  }

  &__btns {
    display: flex;
    gap: 19px;
    align-items: center;
    justify-content: flex-end;
    height: 60px;
  }
}