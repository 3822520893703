.pagination {
  &-container {
    justify-content: flex-end;
    min-height: 56px;
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }

  &-container,
  &-range-actions,
  &-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-range-actions {
    gap: 2rem;
  }

  &-label {
    flex-basis: 100%;
  }

  &-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    font-size: 20px;
    background-color: transparent;
    transition: background-color var(--transition);

    @media (hover) {
      &:hover {
        background-color: var(--gray-75);
        transition: background-color var(--transition), transform var(--transition);
      }
    }

    &:disabled {
      color: var(--gray-200);
      opacity: 0.5;
      pointer-events: none;
    }

    svg {
      display: inline-block;
      flex-shrink: 0;
      width: 1em;
      height: 1em;
      font-size: inherit;
    }

    path {
      fill: var(--dark-900);
    }

    &--next {
      svg {
        transform: scaleX(-1);
      }
    }
  }
}