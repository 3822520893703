.itemHelper {
	position: absolute;
	top: 90px;
	left: 430px;
	z-index: 100;
	display: none;
	flex-direction: column;
	width: 580px;
	height: 11.8rem;
	background: var(--white-100);
	box-shadow: 0 7.677px 120px 0 #00000033;

	&_active {
		display: flex;
		opacity: 0;
		animation: fade-in ease var(--transition);
		animation-delay: var(--transition);
		animation-fill-mode: forwards;
	}

	&__material-container{
		padding-top: 9px;
		padding-bottom: 8px;
		padding-left: 21px;
		background: var(--white-100);
	}

	&__material-header{
		display: flex;
		gap: 5px;
		align-items: center;
	}

	&__material-name{
		display: flex;
		gap: 19px;
		align-items: center;
	}

	&__block-container{
		display: flex;
		flex-direction: column;
		gap: 15px;
	}

	&__material-title{
		margin-bottom: 12px;
	}

	&__edges{
		display: flex;
		flex-direction: column;
		gap: 5px;
		background: var(--gray-50);
	}

	&__edge{
		display: flex;
		align-items: center;
		padding: 4px 0 4px 21px;
		background: var(--white-100);
		box-shadow: var(--container-shadow);
	}

	&__edge-container{
		display: flex;
		gap: 20px;
		align-items: center;
	}

	&__edge-container-img{
		width: 21px;
	}

	&__text{
		color: var(--purple-400);
		font-size: 14px;
	}

	&__text2{
		font-size: 14px;
	}
}

@keyframes fade-in {
	0% {opacity: 0;}
	100% {opacity: 1;}
}