.projects-page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__wrapper {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }

  &__folders {
    width: 275px;
    min-width: 275px;
    height: fit-content;
    padding: 20px 10px;
    border-radius: var(--radius);
    background: var(--white-100);
    box-shadow: 0 1px 2px #00000019;

    &-title {
      margin-bottom: 10px;
      color: var(--gray-700);
    }

    &-list {
      .projects__btn {
        path {
          fill: var(--purple-400);
        }
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        margin: 4px 0;
        padding: 0 32px;
        border-radius: var(--radius);
        color: var(--purple-400);
        font-size: 14px;
        line-height: 100%;
        cursor: pointer;
        transition: var(--transition) all;

        &:hover {
          background: #fcf8fb;
        }

        &.active {
          background: #ffe7f7;
        }
      }
    }

    .add-project {
      margin: 20px;

      .defaultBtn3 {
        width: 100%;
        border-radius: var(--radius);
      }
    }
  }

  &__projects {
      width: 100%;

      &-table {
        width: 100%;

        th {
          height: 50px;
          border: none;
          background: none;
        }

        td {
          height: 45px;
          border: none;
          user-select: none;
        }
      }
  }
}