.edges {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: calc(100% - 97px - 58px);
  padding: 0 33px;
  overflow-y: auto;

  &__none {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 30px;
  }

  &__checkbox {
    margin-right: 20px;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 95px;
    padding: 19px 0 26px;
    border-top: var(--border-width-thin) solid var(--purple-50);

    &:nth-child(odd) {
      width: 860px;
      padding-right: 33px;
      border-right: var(--border-width-thin) solid var(--purple-50);

      @include for-modal-up {
        border-right: none;
      }
    }

    &:nth-child(even) {
      width: 860px;
      padding-left: 44px;
      border-left: var(--border-width-thin) solid var(--purple-50);

      @include for-modal-up {
        padding-right: 31px;
        padding-left: 0;
        border-left: none;
      }
    }

    &-number {
      box-sizing: content-box;
      width: 20px;
      padding-right: 15px;
      text-align: center;
    }
  }

  &__title-container {
    display: flex;
    gap: 27px;
    align-items: center;
    width: 100%;
    max-width: 345px;
  }

  &__name {
    width: 226px;
    font-size: 15px;
    line-height: 18px;

  }

  &__table {
    display: flex;
    align-items: center;
    width: 100%;

    &-name {
      margin: 0 auto;
      font-size: 14px;
      line-height: 17px;

      @include for-modal-up {
        display: none;
      }
    }
  }

  &__data-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 398px;
    padding-right: 71px;

    @include for-modal-up {
      max-width: 362px;
    }
  }

  &__data-container-second {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 410px;
    padding-right: 85px;

    @include for-modal-up {
      display: none;
    }
  }

  &__data {
    width: 60px;
    font-size: 14px;
    line-height: 17px;

    &-item {
      width: 60px;
      text-align: center;
    }
  }
}

.edges-header {
  display: flex;
  align-items: center;
  padding: 15px 33px;

  &__checkbox {
    margin-right: 28px;
    cursor: not-allowed;
  }

  &__title-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 390px;

    @include for-modal-up {
      max-width: 350px;
    }
  }

  &__title {
    color: var(--purple-400);
    font-size: 18px;
    line-height: 22px;
  }
}

.modal {
  position: fixed;
  inset: 0;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 120%;
  background: #272B3019;
  backdrop-filter: var(--backdrop);
}

.modal__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: var(--modal-radius);
  background-color: var(--modal-bg);
  box-shadow: var(--modal-shadow);
  transform: translate(-50%, -50%);
}

.modal__header {
  padding: 10px var(--modal-indent);
  border-top-left-radius: var(--modal-radius);
  border-top-right-radius: var(--modal-radius);
  background-color: var(--modal-header-bg);
}

.modal__actions {
  padding: 10px var(--modal-indent);
  border-bottom-right-radius: var(--modal-radius);
  border-bottom-left-radius: var(--modal-radius);
  background-color: var(--modal-header-bg);
}

.mT-10 {
  margin-top: 10%;
}

.mt-5{
  margin-top: 5px;
}

.mt-20{
  margin-top: 20px;
}

.textPurpl{
  color: var(--purple-400);
  font-weight: var(--medium-weight);
  text-align: center;
}

.align-center{
  text-align: center;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
}