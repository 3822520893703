@import "../../../../styles/mixins.scss";

.back{
  height: 100%;
  overflow-y: hidden;

}

.hardwareTables{
  display: flex;
  width: 100%;
  height: 100%;

  background-color: var(--gray-200);

}

.hardwareRecommended{
  display: flex;
  flex-direction: column;
  width: 35%;
  height: 100%;
  background-color: #FFFFFF;

  &-header{
    margin-top: 25px;
    margin-bottom: 21px;
    margin-left: 16px;
    font-weight: 600;
    font-size: 16px;
  }

  &-data{
    display: flex;
    height: calc(100% - 80px);


    &-furniture{
     width: 65%;
     overflow-y: auto;
      margin-top: 13px;
   }

    &-empty{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 65%;
      height: 100%;
      margin-right: 5px;
      margin-left: 5px;

      &-text{
        color: var(--purple-800);
        font-weight: var(--normal-weight);
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }
  }

  &-category{
    width: 90%;
    height: 100%;
    overflow-y: auto;
    margin-top: 13px;
    margin-left: 5px;

    &-header{
      position: sticky;
      top: 0;
      z-index: 2;
      height: 33px;
      padding: 0.5rem 0 5px 20px;
      border: 0.05rem solid #F3F3F3FF;
      border-top: none;
      border-left: none;
      color: #717171;
      font-weight: 400;
      font-size: 14px;
      background-color: var(--gray-350);
    }
  }

}

.hardwareTab{
  display: flex;

  gap: 4px;
  justify-content: space-between;
  width: 70%;
  height: 100%;
  background-color: var(--gray-200);

  &-add{
    position: relative;

  }

  &_not_find{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: var(--purple-800);
    font-weight: var(--normal-weight);
    font-size: 18px;
    line-height: 22px;
  }

  &__list{
    position: absolute; // Абсолютное позиционирование
    z-index: 100;
    width: 500px;
    max-width: 690px;
    height: 300px;
    max-height: 490px;
    margin-top: 8px;
    overflow-y: scroll;    // Включаем вертикальный скролл
    background-color: var(--white-100);
    box-shadow: 5px 34px 134.9px 0 #D4D6E2A6;
    overflow-x:hidden;

    &-item{
       display: flex;
       align-items: center;
       max-height: 70px;
       padding: 10px;
       transition: background-color 0.3s ease, transform 0.2s ease;
       height: 100%;
      &-text{
        display: flex;
        align-items: center;
        width: 300px;
        margin-right: 14px;
        margin-left: 10px;
        color: #414141;
        font-size: 14px;
        height: 100%;

      }

      &-article{
        width: 30px;
        height: 20px;
        margin-right: 34px;
        font-size: 14px;

      }

      &-price{
        width: 50px;
        height: 20px;
       color: var(--purple-400);
        font-size: 14px;

      }

      &-img{
        width: 50px;
        height: 50px;
      }
    }

  }

  &__list-item:hover {
    background-color: var(--purple-100);
    transform: scale(1.02);
    cursor: pointer;
  }

  &__searchField {
    display: flex;
    align-items: center;
    width: 500px;
    max-width: 690px;
    height: 35px;
    padding-right: 21px;
    padding-left: 21px;
    border-radius: 36.293px;
    background: #E9E9E9;

  }

  &__input {
    width: 370px;
    border: none;
    color: var(--purple-800);
    font-weight: var(--normal-weight);
    font-size: 14px;
    line-height: 19px;
    background-color: transparent;
    outline: none;

    &-icon {
      display: inline-block;
      width: 13px;
      height: 15px;
      margin-right: 15px;
      background: url("../../../../media/loop.png");
    }
  }

  &_oneCol{
    width: 100%;
    height: 100%;
    overflow-y: hidden;


    &__table{
      position: relative;
      box-sizing: border-box;    /* Убедитесь, что padding учитывается правильно */
      padding-bottom: 10px;
      margin-top: 15px;
      overflow-y: auto;
      height: 100%;

      &-sum{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height:55px;
        padding-right: 20px;
        border-top: 2px solid #CED4DAFF;
        background-color: var(--white-100);
        bottom: 0;
        //width: 62.8%;
        width: 62.8vw;
        &-name{
          display: flex;
          align-items: center;
          color: #414141;
          font-size: 16px;
        }

        &-container{
          display: flex;
          margin-left: 20px;

          &-price{
            display: flex;
            align-items: center;
            margin-top: 2px;
            margin-left: 8px;
            color: var(--gray-500);
            font-weight: 600;
            font-size: 16px;
          }
        }

      }
      /* Горизонтальная прокрутка включается, если контент больше доступной высоты */
    }

    &__header{
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: #FFFFFF;

      &_wrap{
        display: flex;
        justify-content: space-between;
        height: 64px;
        padding: 20px 10px 19px 0;


        &_nameCol{
          margin-left: 20px;
          font-weight: 600;
          font-size: 16px;
        }

        &_delete{
          display: flex;
          gap: 15px;
          align-items: center;

          span{
            color: rgb(65 65 65 / 70%);
            font-weight: var(--normal-weight);
            font-size: 14px;
            font-style: normal;
          }
        }
      }




      &_loader{
        height: calc(100vh - 430px);
      }

      &_empty{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 400px;
        color: var(--purple-800);
        font-weight: var(--normal-weight);
        font-size: 20px;
        line-height: 22px;
      }
    }
  }
}

.flex{
  display: flex;
}

.grey-divider {
  width: 97%;
  margin-top: 5px;
  margin-left: 1.5%;
  border-top: 1px solid #D4D4D4;
}

.vertical-line1 {
  width: 8px;
  height: 100%;
  margin-right: 5px;
  margin-left: 5px;
  background-color: rgb(132 132 132 / 10.2%);
}
.loader-container {
  text-align: center;
  margin-top: 120px;
}