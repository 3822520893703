@mixin buttonStyles($backgroundColor, $color) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 1px 54px;
  border-color: transparent;
  border-radius: 30px;
  color: $color;
  font-size: 14px;
  line-height: 17px;
  background-color: $backgroundColor;
  transition: all var(--transition);
}

.defaultBtn {
  @include buttonStyles(#f0e4ed, #414141);

  @media (hover) {
    &:not(:disabled) {
        &:hover {
        color: var(--white-100);
        background: var(--purple-400);
      }
    }
  }

  &:disabled {
    background: var(--purple-50);
    cursor: not-allowed;
  }
}

.defaultBtn2 {
  @include buttonStyles(#f0e4ed, #414141);

  width: 178px;
  height: 30px;
}

.defaultBtn3 {
  @include buttonStyles(#a14389, #ffffff);

  &:hover {
    color: var(--white-100);
    background-color: #7d3168;
  }
}

.defaultBtn4 {
  @include buttonStyles(#414141, #ffffff);

  &:hover {
    color: var(--white-100);
    background-color: #7d3168;
  }
}

.defaultBtn5 {
  @include buttonStyles(#f0e4ed, #414141);

  width: 115px;
  height: 30px;

  &:hover {
    color: var(--white-100);
    background-color: #7d3168;
  }
}

.btn-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}