@import "../../../../styles/mixins.scss";

.addCatalogProducts {
  width: 100%;
  max-width: 1250px;
  height: 90%;
  overflow-y: auto;
  color: var(--purple-800);
  font-weight: var(--normal-weight);
  background-color: #E9E9E9;

  &-grey-divider {
    width: 100%;
    border-top: 1px solid #D4D4D4;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 10px 15px;
    position: sticky; /* Закрепляет заголовок */
    top: 0; /* Расположение по верхнему краю */
    z-index: 10; /* Порядок наложения поверх основного содержимого */
    background-color: #F9F9F9;

    &-title {
      display: flex;

      &-text{
        margin: 0;
        padding: 0 0 0 15px;
        color: var(--purple-800);
        font-weight: var(--normal-weight);
        font-size: 18px;
        line-height: 22px;
      }
    }
  }


  &__main{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content:space-around;
    width: 100%;
    padding: 10px 12px 20px;

    &_loader{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    &_item{
      position: relative;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 290px;
      height: 264px;
      max-height: 320px;
      overflow: hidden;
      background-color: var(--white-100);
      border-radius: 10px;
      box-shadow: 0px 10px 25px 0px #00000026;

      &_act{
        width: 100%;
        height: 13px;
        color: #009951;

        &_text{
           padding-top: 11px;
           padding-left: 16px;
           font-size: 14px;
        }
      }

      &_img{
        width: 126px;
        height: 88px;
      }

      &_blockAdd{
        position: absolute;
        bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 34px;
        padding-top: 10px;
        padding-right: 16px;
        padding-left: 16px;

        &_price{
          color: #A14389;
          font-size: 20px;
        }
      }

      &_article{
        margin-top: 15px;
        margin-left: 16px;
        color: var(--purple-400);
        font-size: 14px;

        &_num{
          color: var(--gray-500);
          font-size: 14px;
        }
      }

      &_imgContainer{
        display: flex;
        justify-content: center;
        margin-top: 8px;
      }

      &_text{
        width: 90%;
        height: 42px;
        margin-top: 5px;
        margin-left: 15px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

      }

    }
  }




  &__btns {
    display: flex;
    gap: 19px;
    align-items: center;
    justify-content: flex-end;
    height: 60px;

    &_wrap{
      display: flex;
      justify-content: space-between;
      width: 25%;
    }
  }

  &__actions {
    display: flex;
    gap: 16px;
    justify-content: space-between;
  }
}



