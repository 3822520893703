.hardware-category{
  width: 97%;
  padding-top: 5px;
  padding-left: 20px;
  //overflow-y: auto;
  background-color: var(--white-100);

  &-item{
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #414141;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      color: var(--purple-400);
    }
  }
}

.tree-node-arrow {
  width: 17px;
  height: 17px;
  margin-right: 7px;
}

.loaderFurniture{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10
}

.tree-node {
  position: relative;

}

.children-container {
  position: relative;
  margin-left: 0;
  padding-left: 0;
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(7px + var(--depth, 0) * 20px);
    width: 2px;
    background-color: #DBDBDB;
    content: '';
  }
}

.children-container .tree-node {
  margin-top: 4px;
}


