.hardware-table-recommended {
  width: 97%;
  margin-left: 1.5%;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: var(--white-100);

  &__wrapper {
    //height: 480px;
    //max-height: 60%;
    //overflow-y: auto;

  }

  &-row-header {
    position: sticky;
    top: 0;
    z-index: 2;
    font-size: 14px;
    background-color: var(--gray-350);

  }

  &-header-item {
    position: relative;
    padding: 0.5rem;
    color: #717171;
    font-weight: 400;
    font-size: 14px;
    text-align: center;

    /* Основные бордеры между колонками */
    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 0.05rem;
      height: 50%; /* Высота только 50% */
      background-color: #CED4DAFF;
      transform: translateY(-50%);
      content: '';
    }

    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      width: 0.05rem;
      height: 50%;
      background-color: #CED4DAFF;
      transform: translateY(-50%);
      content: '';
    }

    &.no-border-left::before {
      left: 0;
      height: 100%; /* Высота на весь элемент */
      background-color: #F3F3F3FF; /* Цвет левого бордера */
    }

    &.no-border-right::after {
      right: 0;
      height: 100%; /* Высота на весь элемент */
      background-color: #F3F3F3FF; /* Цвет правого бордера */
    }
  }

  &-header-name {
    padding: 0.5rem 0.5rem 0.5rem 10px;
    font-weight: 400;
    font-size: 14px;
    text-align: start;
  }

  &-item {
    padding-top: 20px;
    padding-bottom: 20px;
    border: 0.05rem solid #F3F3F3FF;
    border-left: none;
    color: var(--gray-500);
    font-size: 16px;
    white-space: nowrap;
    &:hover {
      color: #A14389;
      cursor: pointer;
    }
    &-first{
      padding-left: 20px;

    }
    &-img{
      margin-right: 5px;
      margin-left: 3px;
      width: 20px;
      height: 20px;
      padding-top: 8px;
    }

    &__subText{
      overflow: hidden;
      color: rgb(65 65 65 / 70%);
      font-weight: var(--normal-weight);
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      white-space: nowrap;
      text-overflow: ellipsis;

      &_link{
        color: var(--gray-500);
        font-weight: var(--normal-weight);
        font-size: 16px;
        line-height: 19.2px;
      }

      &_link:hover{
        color: var(--purple-400);
      }

      &_link:visited{
        color: #470B38;
      }
    }

    &__right{
     text-align: center;
    }

    &_img{
      width: 73px;
      height: 55px;
      margin-top: 5px;
      border-radius: var(--radius);
      box-shadow: 0 6.67903px 119px rgb(149 149 149 / 24%);
    }

    &__btns{
      display: flex;
      justify-content: center;
    }

    &__button{
      color: var(--purple-400);
      font-size: 20px;
      background-color: var(--white-100);
      //padding: 4px 8px;
      cursor: pointer;
    }

    &__button:hover {
      color: var(--purple-800);
    }

    &__default-input-small {
      width: 60px;
      height: 30px;
      border: none;
      border-radius: var(--radius);
      color: rgb(65 65 65 / 70%);
      font-size: 14px;
      text-align: center;
      background-color: var(--form-field-bg);
      outline: none;
    }

    &-first-item {
      width: 3rem;
      overflow: hidden;
      background-color: var(--white-100);
      //background-color: #F3F3F3FF;
      padding-block: 0.5rem;
    }

    &__name {
      padding-left: 10px;
      border: 0.05rem solid #CED4DAFF;
      color: #1E152AFF;
      font-size: 14px;
      white-space: nowrap;
      text-align: left;
    }
  }
}
