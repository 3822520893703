.contourEdgeHelper {
  position: absolute;
  top: 110px;
  left: 10%;
  z-index: 100000;
  width: 607px;
  height: 123px;
  padding: 8px;
  border-radius: 4px;
  background: white;
  box-shadow: 0 10px 25px 0 rgb(0 0 0 / 15%);

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    background: #EFEFEF;

    &-item {
      width: 100px;
      padding: 0 12px;
      color: var(--gray-300);
      font-size: 13px;
      text-align: center;

      &:not(:last-child) {
        position: relative;

        &::after {
          position: absolute;
          top: 50%;
          right: 0;
          width: 1px;
          height: 14px;
          background: #D4D4D4;
          transform: translateY(-50%);
          content: "";
        }
      }
    }

  }

  &__body{
    display: flex;
    align-items: center;
    width: 100%;
    height: 64px;

    &-item{
      width: 100px;
      padding: 0 12px;
      color: var(--gray-500);
      text-align: center;

    }
  }

  &__sketch{
    width: 100px
  }

  &__name{
    width: 255px;
    font-size: 14px;
    text-align: start;
  }

  &__article{
    width: 100px;
  }
}