.modal-map-project {
  width: 80%;
  height: auto;
  max-height: 85vh;


  &__header{
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
  }

  &__title {
    font-size: 18px;
    line-height: 22px;

  }

  &__body {
    min-height: 200px;
    margin-top: 10px;
    overflow-y: auto;
  }

  &__btns {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    width: 100%;
  }

  &__form-control {
    display: flex;
    flex-direction: column;

    &-label {
      margin-bottom: 13px;
      font-size: 14px;
      line-height: 17px;

    }

    &-input {
      display: flex;
      align-items: center;
      height: 49px;
      padding: 0 19px;
      border: var(--border-width-thin) solid var(--dark-1000);
      border-radius: var(--form-field-radius);
      line-height: 19px;

    }

    &-select {
      display: flex;
      align-items: center;
      height: 49px;
      border: var(--border-width-thin) solid var(--dark-1000);
      border-radius: var(--form-field-radius);
      line-height: 19px;
      cursor: pointer;
    }

    &-options {
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: 100%;
      margin-top: 22px;

      &_active {
        display: none;
      }

      &_item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 46px;
        padding: 0 25px;
        border-radius: 9px;
        font-size: 22px;
        line-height: 26px;
        background: #f2eaf1;
      }
    }
  }
}

.warning-message{
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 28px;
  margin-bottom: 10px;
  background: #F5EDED;
}

.warning-message-text{
  color: var(--error);
  font-size: 14px;
}

.warning-icon{
   margin-right: 21px;
   margin-left: 37px;
 }

.table__body{
  overflow-y: auto;
}
