.inputExelModal{
	width: 40%;
	max-width: 40%;
	height: 40%;
	max-height: 40%;


	&__header{
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	&__container{
		display: flex;
		align-items: center;
		margin-left: 10px;
		font-size: 14px;
		white-space: nowrap;
	}

	&__body{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 85%;
		text-align: center;

		&-wrapper{
			display: flex;
			justify-content: center;
			width: 100%;

          &-icon {
            display: flex;

            path {
              fill: var(--purple-400);
            }
          }

			&-text{
				margin-right: 10px;


			}

			&_link{
				padding-right: 5px;
				color: var(--purple-400);

			}
		}

		&__header{
			display: flex;
			padding-top: 15px;

			&_input{
				display: none;
				width: 100%;
				border: var(--border-width-thin) solid var(--purple-400);
				border-radius: var(--radius);
			}

			&_upload {
				display: inline-block;
				padding: 6px 12px;
				border: var(--border-width-thin) solid var(--purple-400);
				border-radius: var(--radius);
				cursor: pointer;
			}

			&_upload:hover {
				color: var(--white-100);
				background: var(--purple-400)
			}

			&_name{
				width: 60%;
				padding-left: 5px;
				border: var(--border-width-thin) solid var(--purple-400);
				border-radius: var(--radius);
			}
		}
	}

	&__main{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	&_id{
		display: flex;
		gap: 20px;
		width: 100%;
	}
}

.bgSelected{
	background: #f0e4ed;
}

.icon-linkXlsx{
	display: inline-block;
	width: 45px;
	height: 29px;
	background-image: url("../../../../media/exportXlsx.svg");
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}